import React, { useMemo, useRef, useState } from 'react'
import './flight.css'
import { GoArrowSwitch } from "react-icons/go";
import { CiSearch } from "react-icons/ci";
import { useClickedOutside } from './clickedOutsideHook';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


const airportSearchResultData = {
    "results": {
        "SUGGESTIONS": {
            "title": "SUGGESTIONS",
        }
    },
    "sectionsOrder": {
        "NEARBY_AIRPORTS": 0,
        "MORE_CITIES": 0,
        "SUGGESTIONS": 1,
        "INCREDIBLE_INDIA": 0
    }
  }

export const FlightComp = ({index,data,setData, travellersAndClass,setTravellersAndClass, isLast}) => {

  const [airportSuggestions, setAirportSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchAirportData = async (query) => {
    try {
      const response = await fetch(`https://chalophir.com/admin/api/airportsearch?search=${query}`);
      const data = await response.json();
      setAirportSuggestions(data.results); // Adjust based on your API response structure
    } catch (error) {
      console.error("Error fetching airport data:", error);
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value) {
      // Call fetch function after a delay
      const debounceTimeout = setTimeout(() => {
        fetchAirportData(value);
      }, 300); // 300ms debounce time
      return () => clearTimeout(debounceTimeout);
    } else {
      setAirportSuggestions([]); // Clear suggestions if input is empty
    }
  };

    const [openFrom, setOpenFrom] = useState(false)
    const fromRef = useRef(null)
    useClickedOutside(fromRef,setOpenFrom)
  
    const [openTo, setOpenTo] = useState(false)
    const toRef = useRef(null)
    useClickedOutside(toRef,setOpenTo)

    const [openDepartureDate, setOpenDepartureDate] = useState(false)
    const departureDateRef = useRef(null)
    useClickedOutside(departureDateRef,setOpenDepartureDate)
    
    // const [travellersAndClass, setTravellersAndClass] = useState({ class:"Economy/Premium Economy", adults:1, children:0, infants:0 })
    const [openTravellersAndClass, setOpenTravellersAndClass] = useState(false)
    const travellersAndClassRef = useRef(null)
    useClickedOutside(travellersAndClassRef,setOpenTravellersAndClass)

    const dateOptions = useMemo(() => ( { weekday: 'long', year: '2-digit', month: 'short', day: 'numeric' }), [])

  return (
    <div className='flight-second-box mb-2'>
        
    <div className='flight-from' >
      <div onClick={()=> setOpenFrom(true)}>
        <p>From</p>
        <div>
          <h3>{data[index].from.cityName.length>12?data[index].from.cityName.slice(0,15)+"...":data[index].from.cityName}</h3>
          <p>{data[index].from.iata}, {data[index].from.airportName.length>35?data[index].from.airportName.slice(0,35)+"...":data[index].from.airportName}</p>
        </div>

      </div>
      { 
        openFrom &&
        <div className='airport-search-container' ref={fromRef}>
          <div>
            <div id='input-box'>
              <span><CiSearch/></span>
              <input type="text"  onChange={handleInputChange} placeholder='From' autoFocus={true}/>
            </div>
            <div className='search-result-display'>
              <p className='search-result-display-title'>{airportSearchResultData.results?.SUGGESTIONS.title}</p>
              {airportSuggestions.map((e,i)=>{
                return <div key={i} className='search-result-display-item'
                onClick={()=>{
                  const newData = [...data]
                  newData[index].from = e
                  setData(newData)
                  setOpenFrom(false)
                }}
                >
                      
                      <div className='search-result-display-city-iata'>
                        <div className='d-flex gap-2 w-100'>
                          <img src={e.icon} alt="icon" />
                          <div>
                            <p id='search-result-display-city'>{e.cityName}</p>
                            <p id='search-result-display-airport'>{e.airportName}</p>
                          </div>
                        </div>
                          <p id='search-result-display-iata'>{e.iata}</p>
                      </div>
                    
                      
                </div>
              })}
            </div>
          </div>
        </div>
      }
    </div>

    <div className='switch-button ' onClick={()=>{
        const newData = [...data]
        const temp = {...newData[index].to}
        newData[index].to = {...newData[index].from}
        newData[index].from = temp
        setData(newData)
    }}>
      <div className=''>
        <GoArrowSwitch color='#e03a3c'/>
      </div>
    </div>

    <div className='flight-to'>
      <div onClick={()=> setOpenTo(true)}>
        <p>To</p>
        <div>
          <h3>{data[index].to.cityName.length>12?data[index].to.cityName.slice(0,15)+"...":data[index].to.cityName}</h3>
          <p>{data[index].to.iata}, {data[index].to.airportName.length>35?data[index].to.airportName.slice(0,35)+"...":data[index].to.airportName}</p>
        </div>
      </div>
      { 
        openTo &&
        <div className='airport-search-container airport-search-container-to' ref={toRef}>
          <div>
            <div id='input-box'>
              <span><CiSearch/></span>
              <input type="text"  onChange={handleInputChange} placeholder='To' autoFocus={true}/>
            </div>
            <div className='search-result-display'>
              <p className='search-result-display-title'>{airportSearchResultData.results.SUGGESTIONS.title}</p>
              {airportSuggestions.map((e,i)=>{
                return <div key={i} className='search-result-display-item'
                onClick={()=>{
                  const newData = [...data]
                  newData[index].to = e
                  setData(newData)
                  setOpenTo(false)
                  }}
                >
                      
                      <div className='search-result-display-city-iata'>
                        <div className='d-flex gap-2 w-100'>
                          <img src={e.icon} alt="icon" />
                          <div>
                            <p id='search-result-display-city'>{e.cityName}</p>
                            <p id='search-result-display-airport'>{e.airportName}</p>
                          </div>
                        </div>
                          <p id='search-result-display-iata'>{e.iata}</p>
                      </div>
                      
                </div>
              })}
            </div>
          </div>
        </div>
      }
    </div>
    
    <div className='flight-date'>
      <div className='flight-date-departure' >
        <div onClick={()=> setOpenDepartureDate(true)}>
          <p className=''>Departure <span style={{ color:"#e03a3c"}}><i  style={{WebkitTextStroke: '2px'}} className="bi bi-chevron-down"></i></span></p>
          <div>
            <div className='d-flex align-items-end gap-1'>
            <h3>{data[index].departureDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[2]}</h3>
            
            <p className='fs-5'>{data[index].departureDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[1]}'{data[index].departureDate.toLocaleDateString('en-US', dateOptions).split(',')[2].trim()}
            </p>
            </div>
            <p>{data[index].departureDate.toLocaleDateString('en-US', dateOptions).split(',')[0]}</p>
          </div>
        </div>
        { 
          openDepartureDate &&
          <div className='airport-search-container airport-search-container-date' ref={departureDateRef}>
            <div>
              <div className=''>
              <div>
                <div>
                  
                </div>
                <div>
                  <Calendar 
                    // returnValue={'range'}
                    onChange={(e)=>{
                        const newData = [...data]
                        newData[index].departureDate = e
                        setData(newData)
                      setOpenDepartureDate(false)
                      }} 
                    goToRangeStartOnSelect={false}
                    showNeighboringMonth={false}
                    value={data[index].departureDate} 
                    // selectRange={true} 
                    // showDoubleView={true} 
                    minDate={new Date()}
                    showFixedNumberOfWeeks={false}
                    allowPartialRange={false}
                  />
                </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    <div className={`flight-travellers ${index!==0?"flight-travellers-multi":null}`}>
      {(index === 0 && <>
      <div onClick={()=> setOpenTravellersAndClass(true)}>
        <p>Travellers & Class <span style={{ color:"#e03a3c"}}><i  style={{WebkitTextStroke: '2px'}} className="bi bi-chevron-down"></i></span></p>
        <div>
            <div className='d-flex align-items-end gap-1'>
            <h3>{travellersAndClass.adults+travellersAndClass.children+travellersAndClass.infants}</h3>
            
            <p className='fs-5'>Travellers
            </p>
            </div>
            <p>{travellersAndClass.class}</p>
        </div>
      </div>
      { 
        openTravellersAndClass &&
        <div className='airport-search-container airport-search-container-travellers' ref={travellersAndClassRef}>
          <div>
            <div>
              <p id='flight-travellers-title'>ADULTS (12y +)</p> 
              <p id='flight-travellers-subtitle'> on the day of travel</p>
            </div>
            <div id='flight-travellers-numbers'>
              {[1,2,3,4,5,6,7,8,9].map((e,i)=>{
                return <span key={i} 
                onClick={()=>setTravellersAndClass(pre=>({...pre,adults:e}))}
                style={e===travellersAndClass.adults ? {backgroundColor:"#e03a3c",color:'#fff'}:null}>{e}</span>
              })}
              <span 
              style={'>9'===travellersAndClass.adults ? {backgroundColor:"#e03a3c",color:'#fff'}:null}
              onClick={()=>setTravellersAndClass(pre=>({...pre,adults:'>9'}))}>&gt;9</span>
            </div>
          <div className='d-flex gap-5 mt-3'>
            <div>
              <div>
                <p id='flight-travellers-title'>CHILDREN (2y - 12y )</p> 
                <p id='flight-travellers-subtitle'>on the day of travel</p>
              </div>
              <div id='flight-travellers-numbers'>
                {[1,2,3,4,5,6].map((e,i)=>{
                  return <span key={i} 
                  onClick={()=>setTravellersAndClass(pre=>({...pre,children:e}))}
                  style={e===travellersAndClass.children ? {backgroundColor:"#e03a3c",color:'#fff'}:null}>{e}</span>
                })}
                <span 
                style={'>6'===travellersAndClass.children ? {backgroundColor:"#e03a3c"}:null}
                onClick={()=>setTravellersAndClass(pre=>({...pre,children:'>6'}))}>&gt;6</span>
              </div>
            </div>
            <div>
              <div>
                <p id='flight-travellers-title'>NFANTS (below 2y)</p> 
                <p id='flight-travellers-subtitle'>on the day of travel</p>
              </div>
              <div id='flight-travellers-numbers'>
                {[1,2,3,4,5,6].map((e,i)=>{
                  return <span key={i} 
                  onClick={()=>setTravellersAndClass(pre=>({...pre,infants:e}))}
                  style={e===travellersAndClass.infants ? {backgroundColor:"#e03a3c",color:'#fff'}:null}>{e}</span>
                })}
                <span 
                style={'>6'===travellersAndClass.infants ? {backgroundColor:"#e03a3c",color:'#fff'}:null}
                onClick={()=>setTravellersAndClass(pre=>({...pre,infants:'>6'}))}>&gt;6</span>
              </div>
            </div>
          </div>
          <div className='mt-3'>
          <p id='flight-travellers-title'>CHOOSE TRAVEL CLASS</p> 
          <div id='flight-travellers-numbers'>
              {['Economy/Premium Economy','Premium Economy','Business'].map((e,i)=>{
                return <span key={i} 
                onClick={()=>setTravellersAndClass(pre=>({...pre,class:e}))}
                style={e===travellersAndClass.class ? {backgroundColor:"#e03a3c",color:'#fff'}:null}>{e}</span>
              })}
            </div>
          </div>
          <div className='mt-3 d-flex justify-content-end '>
            <button
                onClick={()=>setOpenTravellersAndClass(pre=>!pre)}
                className='apply-button'>APPLY
            </button>
        </div>
          </div>
        </div>
      }
      </>)      
      }
      {
        isLast && <>
            <div className='d-flex align-items-center justify-content-center h-100 gap-3'>
                <button
                    onClick={()=>{
                        setData(pre=>[...pre,  {
                        from:{iata:"BOM",cityName:'Mumbai',airportName:"Chhatrapati Shivaji International Airport India"},
                        to:{iata:"GOI",cityName:'Goa - Dabolim Airport',airportName:"Goa Dabolim International Airport India"},
                        departureDate: new Date()
                    } ])
                    }}
                 style={{backgroundColor:"#fff", fontSize:".8rem", fontWeight:"800", border:"1px solid red", padding:".2rem", paddingLeft:"1rem",paddingRight:"1rem", borderRadius:".5rem"}}>+ ADD ANOTHER CITY</button>
                {index !== 1 &&
                <i 
                onClick={()=>{
                        const newData = [...data]
                        newData.pop()
                        setData(newData)

                }}
                style={{fontSize:"1.5rem", cursor:'pointer'}} className="bi bi-x-circle-fill"/>
                }
            </div>
        </>
      }
    </div>
  </div>
  )
}
