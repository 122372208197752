import React, { useState } from 'react'
import './index.css'
import Flight from './flight'
import { useNavigate } from 'react-router-dom';



const Search = () => {
  const navigate = useNavigate();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [searchData, setSearchData] = useState(null);

  const [tabsNavigationData, setTabsNavigationData] = useState([
    {
      img:<i className="bi bi-airplane"></i>,
      text:"Flights",
      isActive: true,
      componentToRender: <Flight onSearch={setSearchData} />
    },
    {
      img:<i className="bi bi-buildings"></i>,
      text:"Hotels",
      isActive: false,
      componentToRender: <>Hotels</>
    },
    {
      img:<i className="bi bi-houses-fill"></i>,
      text:"Packages",
      isActive: false,
      componentToRender: <>Homestays & Villas</>
    },
    {
      img:<i className="bi bi-credit-card"></i>,
      text:"Visa Checklist",
      isActive: false
    },
    {
      img:<i className="bi bi-pip"></i>,
      text:"E-Visa",
      isActive: false
    },
  ])
  const handleSearch = () => {
    const from_val = document.getElementById('from_value').value;
    const to_value = document.getElementById('to_value').value;
    const from_country = document.getElementById('from_country').value;
    const to_country = document.getElementById('to_country').value;
    const departure_date = document.getElementById('departure_date').value;
    const trip_value = document.getElementById('trip_value').value;
    const travel_data = document.getElementById('travel_data').value;
    const params = new URLSearchParams({
      from_val,
      to_value,
      from_country,
      to_country,
      departure_date,
      trip_value,
      travel_data,
  });

  // Navigate to the destination page with query parameters
  navigate(`/flight?${params.toString()}`);
  };


  return (
    <div className='search-main-container'>
        <div className='search-tabs-navigation'>
          {tabsNavigationData.map((e,i)=>{
            return <div 
            className='search-tabs-item'
              key={i}
              style={e.isActive?
              {
                borderBottom:".2rem solid #133957",
                color:"#133957",
                fontWeight:"bold"
              }
              :null}
              onClick={()=>setTabsNavigationData(pre => pre.map((ee,ii)=>(i === ii?{...ee, isActive:true}:{...ee, isActive:false})))}
            >
            {e.text === "Flights" && (
              <div>
                <div style={{ fontSize: "1.5rem" }}>
                  {e.img}
                </div>
                <p>
                  {e.text}
                </p>
                </div>
            )}
            {e.text === "Hotels" && (
        <a href="/hotel" className='flight_text'>
          <div style={{ fontSize: "1.5rem" }}>
            {e.img}
          </div>
          <p>
            {e.text}
          </p>
        </a>
      )}
      {e.text === "Packages" && (
        <a href="/packages" className='flight_text'>
          <div style={{ fontSize: "1.5rem" }}>
            {e.img}
          </div>
          <p>
            {e.text}
          </p>
        </a>
      )}
      {e.text === "Visa Checklist" && (
        <a href="/checklist" className='flight_text'>
          <div style={{ fontSize: "1.5rem" }}>
            {e.img}
          </div>
          <p>
            {e.text}
          </p>
        </a>
      )}
      {e.text === "E-Visa" && (
        <a href="/evisa" className='flight_text'>
          <div style={{ fontSize: "1.5rem" }}>
            {e.img}
          </div>
          <p>
            {e.text}
          </p>
        </a>
      )}
            
            </div>
            
          })}
        </div>
        <div className='search-items-main-container'>
          {tabsNavigationData.filter(e=>e.isActive)[0].componentToRender}
        </div>
        <div id='search-button-container'>
            <button id='search-button' onClick={handleSearch}>SEARCH</button>
        </div>
    </div>
  )
}

export default Search