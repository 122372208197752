import React from 'react'

export default function BookingDetails() {
  return (
    <>
        <div className="pagewrap modify-search">
        <div className="head-wrapper">
            <div className="flight-search">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <span className="fw-bold theme-text-white">Review your booking</span>
                        </div>
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <ul className="reviewStatus step2">
                                <li><span className="numbering completed">1</span><span className="reviewText grayText font12 ">Flight Selected</span></li>
                                <li><span className="numbering onpage">2</span><span className="reviewText grayText font12 active">Review</span></li>
                                <li><span className="numbering ">3</span><span className="reviewText grayText font12 ">Traveller
                                        &amp; Addons</span></li>
                                <li><span className="numbering ">4</span><span className="reviewText grayText font12 ">Make
                                        Payment</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
        <div className="content-section">
           
            <div className="review-flight py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-9 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <div className="d-flex justify-content-between align-items-start mb-3">
                                <div className="fw-bold">Flight Details</div>
                                <div>
                                    <span className="font-small"><i className="bi bi-arrow-left mx-2"></i>Back To Search</span>
                                </div>
                            </div>
                           
                            <div className="theme-box-shadow theme-border-radius bg-light mb-3">
                                <div className="row border-bottom py-2 m-auto">
                                    <div className="col-8">
                                        <span className="fw-bold">
                                            <span className="text-uppercase">Depart - </span>
                                            <span>New Delhi To Kochi</span>
                                        </span>
                                    </div>
                                    <div className="col-4 text-end">
                                        <span className="font-small">Fare Rules</span>
                                        <i className="bi bi-info-circle-fill pl-1"></i>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="px-3">
                                            <ul className="row py-3">
                                                <li className="col-12 pb-3">
                                                    <div className="float-start">
                                                        <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/D-indigo.jpg" alt="indigo" />
                                                    </div>
                                                    <div className="float-start ms-2">
                                                        <div className="font-medium">Spicejet</div>
                                                        <div className="font-small">SG-191</div>
                                                    </div>
                                                    <div className="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                        Class - SS</div>
                                                    <div className="font-medium float-start mx-5">Economy</div>
                                                    <div className="font-medium float-start mx-5">Refundable</div>

                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3">
                                                    <div className="font-medium fw-bold text-uppercase">New Delhi
                                                    </div>
                                                    <div className="font-medium fw-bold">07:20 | <span className="fw-normal">Sat,
                                                            Mar 30</span></div>
                                                    <div className="font-small">Indira Gandhi, T - 1D</div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3">
                                                    <div className="float-start"><i className="bi bi-clock pe-2 fs-6"></i></div>
                                                    <div className="float-start"> <span className="font-medium d-block">03h
                                                            15m</span>
                                                        <span className="font-small d-block">Non Stop</span> <span className="font-small d-block">Equipment:737</span>
                                                    </div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3">
                                                    <div className="font-medium fw-bold text-uppercase">KOCHI</div>
                                                    <div className="font-medium fw-bold">10:35 | <span className="fw-normal">Sat,
                                                            Mar 30</span></div>
                                                    <div className="font-small">Cochin, T - 2</div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3"> <span className="font-small text-uppercase fw-bold"> <i class="bi bi-briefcase me-2 fs-6"></i> Baggage </span> <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span class="font-small d-block">CABIN : 7 Kgs</span> </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="theme-box-shadow theme-border-radius bg-light mb-3">
                                <div className="row border-bottom py-2 m-auto">
                                    <div className="col-8">
                                        <span className="fw-bold">
                                            <span className="text-uppercase">Return - </span>
                                            <span>Kochi To New Delhi</span>
                                        </span>
                                    </div>
                                    <div className="col-4 text-end">
                                        <span className="font-small">Fare Rules</span>
                                        <i className="bi bi-info-circle-fill pl-1"></i>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="px-3">
                                            <ul className="row py-3">
                                                <li className="col-12 pb-3">
                                                    <div className="float-start">
                                                        <img src="assets/images/icons/D-goair.jpg" alt="goair" />
                                                    </div>
                                                    <div className="float-start ms-2">
                                                        <div className="font-medium">Spicejet</div>
                                                        <div className="font-small">SG-191</div>
                                                    </div>
                                                    <div className="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                        Class - SS</div>
                                                    <div className="font-medium float-start mx-5">Economy</div>
                                                    <div className="font-medium float-start mx-5">Refundable</div>

                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3">
                                                    <div className="font-medium fw-bold text-uppercase">KOCHI
                                                    </div>
                                                    <div className="font-medium fw-bold">14:05 | <span className="fw-normal">Sat,
                                                            Mar 30</span></div>
                                                    <div className="font-small">Indira Gandhi, T - 1D</div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3">
                                                    <div className="float-start"><i className="bi bi-clock pe-2 fs-6"></i></div>
                                                    <div className="float-start"> <span className="font-medium d-block">03h
                                                            15m</span>
                                                        <span className="font-small d-block">Non Stop</span> <span className="font-small d-block">Equipment:737</span>
                                                    </div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3">
                                                    <div className="font-medium fw-bold text-uppercase">New Delhi</div>
                                                    <div className="font-medium fw-bold">15:30 | <span className="fw-normal">Sat,
                                                            Mar 30</span></div>
                                                    <div className="font-small">Cochin, T - 2</div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3"> <span className="font-small text-uppercase fw-bold"> <i className="bi bi-briefcase me-2 fs-6"></i> Baggage </span> <span className="font-small d-block">CHECK-IN : 15 Kgs</span><span className="font-small d-block">CABIN : 7 Kgs</span> </li>
                                                <li className="col-12 mb-3 text-center">
                                                    <div className="border d-inline-block theme-border-radius font-small p-2">
                                                        Change planes at Mumbai, Connecting Time: 2h 50m Connecting
                                                        flight may depart from a different terminal</div>
                                                </li>
                                                <li className="col-12 pb-3">
                                                    <div className="float-start">
                                                        <img src="assets/images/icons/D-goair.jpg" alt="goair" />
                                                    </div>
                                                    <div className="float-start ms-2">
                                                        <div className="font-medium">Spicejet</div>
                                                        <div className="font-small">SG-191</div>
                                                    </div>
                                                    <div className="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                        Class - SS</div>
                                                    <div className="font-medium float-start mx-5">Economy</div>
                                                    <div className="font-medium float-start mx-5">Refundable</div>

                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3">
                                                    <div className="font-medium fw-bold text-uppercase">KOCHI
                                                    </div>
                                                    <div className="font-medium fw-bold">14:05 | <span className="fw-normal">Sat,
                                                            Mar 30</span></div>
                                                    <div className="font-small">Indira Gandhi, T - 1D</div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3">
                                                    <div className="float-start"><i className="bi bi-clock pe-2 fs-6"></i></div>
                                                    <div className="float-start"> <span className="font-medium d-block">03h
                                                            15m</span>
                                                        <span className="font-small d-block">Non Stop</span> <span className="font-small d-block">Equipment:737</span>
                                                    </div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3">
                                                    <div className="font-medium fw-bold text-uppercase">New Delhi</div>
                                                    <div className="font-medium fw-bold">15:30 | <span className="fw-normal">Sat,
                                                            Mar 30</span></div>
                                                    <div className="font-small">Cochin, T - 2</div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3 pb-3"> <span className="font-small text-uppercase fw-bold"> <i className="bi bi-briefcase me-2 fs-6"></i> Baggage </span> <span className="font-small d-block">CHECK-IN : 15 Kgs</span><span className="font-small d-block">CABIN : 7 Kgs</span> </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <div className="d-flex justify-content-between align-items-start mb-3">
                                <div className="fw-bold">Add-ons</div>
                            </div>
                            <div className="theme-box-shadow theme-border-radius bg-light mb-3 p-3">
                                <div className="d-flex align-items-center mb-3">
                                    <div className="flex-shrink-0">
                                        <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/travel-secure-icon.png" alt="Travel Secure" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <span className="fw-bold">Secure your trip</span>
                                        <p className="font-small mb-0">See all the benefits you get
                                            for
                                            just $ 159 (18% GST
                                            included)
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex flex-column">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label font-medium" for="flexRadioDefault1">
                                                Yes, secure my trip, I agree to the <a href="#">Terms &amp; Conditions</a> &amp;
                                                <a href="#">Good Health</a>
                                                terms, and confirm all passengers are
                                                between 2 to 70 years of age

                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked="" />
                                            <label className="form-check-label font-medium" for="flexRadioDefault2">
                                                No, I do not wish to secure my trip
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 aos-init aos-animate" data-aos="fade-down" data-aos-delay="300">
                            <div className="fw-bold mb-3">Fare Details</div>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="theme-box-shadow theme-border-radius bg-light">
                                        <ul className="">
                                           
                                            <li className="border-bottom p-3 font-medium">
                                                <a className="d-flex justify-content-between theme-text-accent-one" data-bs-toggle="collapse" href="#baseFare" role="button" aria-expanded="false" aria-controls="baseFare">
                                                    <span className="font-medium fw-bold">
                                                        Base Fare</span>
                                                    <span>
                                                        <i className="bi bi-plus-circle-fill theme-text-accent-two"></i>
                                                    </span>
                                                </a>
                                                <div className="collapse" id="baseFare">
                                                    <div className="d-flex justify-content-between pt-3">
                                                        <span className="font-medium">Adult(s)
                                                            (1 X ₹ 11,545)</span>
                                                        <span className="font-medium">
                                                            <i className="bi bi-currency-dollar"></i>
                                                            <span className="fw-normal">11,545</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                           
                                            <li className="border-bottom p-3 font-medium">
                                                <a className="d-flex justify-content-between theme-text-accent-one" data-bs-toggle="collapse" href="#airlineSurcharge" role="button" aria-expanded="false" aria-controls="airlineSurcharge">
                                                    <span className="font-medium fw-bold">
                                                        Airline Fuel Surcharge</span>
                                                    <span>
                                                        <i className="bi bi-plus-circle-fill theme-text-accent-two"></i>
                                                    </span>
                                                </a>
                                                <div className="collapse" id="airlineSurcharge">
                                                    <div className="d-flex justify-content-between pt-3">
                                                        <span className="font-medium">Adult(s)
                                                            (1 X ₹ 8,0)</span>
                                                        <span className="font-medium">
                                                            <i className="bi bi-currency-dollar"></i>
                                                            <span className="fw-normal">800</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            
                                            <li className="border-bottom p-3 font-medium">
                                                <a className="d-flex justify-content-between theme-text-accent-one" data-bs-toggle="collapse" href="#otherCharges" role="button" aria-expanded="false" aria-controls="otherCharges">
                                                    <span className="font-medium fw-bold">
                                                        Other Taxes</span>
                                                    <span>
                                                        <i className="bi bi-plus-circle-fill theme-text-accent-two"></i>
                                                    </span>
                                                </a>
                                                <div className="collapse" id="otherCharges">
                                                    <div className="d-flex justify-content-between pt-3">
                                                        <span className="font-medium">Passenger Service Fee</span>
                                                        <span className="font-medium">
                                                            <i className="bi bi-currency-dollar"></i>
                                                            <span className="fw-normal">480</span>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex justify-content-between pt-3">
                                                        <span className="font-medium">Airline GST</span>
                                                        <span className="font-medium">
                                                            <i className="bi bi-currency-dollar"></i>
                                                            <span className="fw-normal">396</span>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex justify-content-between pt-3">
                                                        <span className="font-medium">Other Taxes</span>
                                                        <span className="font-medium">
                                                            <i className="bi bi-currency-dollar"></i>
                                                            <span className="fw-normal">1151</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                           
                                            <li className="border-bottom p-3 font-medium">
                                                <div className="d-flex justify-content-between">
                                                    <span className="font-medium fw-bold text-uppercase">
                                                        HC</span>
                                                    <span>
                                                        <i className="bi bi-currency-dollar"></i><span className="fw-normal">480</span>
                                                    </span>
                                                </div>
                                            </li>
                                            
                                            <li className="border-bottom p-3 font-medium">
                                                <div className="d-flex justify-content-between">
                                                    <span className="font-medium fw-bold text-uppercase">
                                                        GST</span>
                                                    <span>
                                                        <i className="bi bi-currency-dollar"></i><span className="fw-normal">80</span>
                                                    </span>
                                                </div>
                                            </li>
                                           
                                            <li className="border-bottom p-3 font-medium">
                                                <div className="d-flex justify-content-between">
                                                    <span className="fs-6 fw-bold">
                                                        Grand Total</span>
                                                    <span className="fs-6">
                                                        <i className="bi bi-currency-dollar"></i><span className="fw-bold">14952</span>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="theme-box-shadow theme-border-radius bg-light p-3 font-small">
                                        Cancellation &amp;
                                        Date change
                                        charges <a href="#" className="">More</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-effect btn-book" onclick="window.location.href='traveller-details.html';">Continue</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        

    </div>
    </>
  )
}
