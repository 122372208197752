import React, { useEffect, useState } from 'react';

export default function Flight() {
    const headerStyle = {
        backgroundImage: "url('/images/page-title-bg.jpg')",
        // Add other styles as necessary, e.g., height, backgroundSize, etc.
      };
      const ShimmerPlaceholder = () => (
        <div className="shimmer-wrapper">
          <div className="shimmer-circle shimmer"></div>
          <div className="w-100 ms-3">
            <div className="shimmer-rectangle shimmer"></div>
            <div className="shimmer-text shimmer"></div>
          </div>
        </div>
      );
      
      const [flightData, setFlightData] = useState([]);
      const [isLoading, setIsLoading] = useState(true);
      useEffect(() => {
        // Extract the query parameters
        const params = new URLSearchParams(window.location.search);
        const fromVal = params.get('from_val');
        const toValue = params.get('to_value');
        const fromCountry = params.get('from_country');
        const toCountry = params.get('to_country');
        const departureDate = params.get('departure_date');
        const tripValue = params.get('trip_value');
        const travelData = params.get('travel_data');
    
        // Create an object to send to the API
        const dataToSend = {
          fromVal,
          toValue,
          fromCountry,
          toCountry,
          departureDate,
          tripValue,
          travelData,
        };
    
        // Send data to the API
        sendToApi(dataToSend);
      }, []);
      const sendToApi = async (data) => {
        try {
            setIsLoading(true); // Start loading
      
            const response = await fetch('https://chalophir.com/admin/api/flightsearch', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            });
      
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
      
            const result = await response.json();
            setFlightData(result.results);
          } catch (error) {
            console.error('Error sending data to API:', error);
          } finally {
            setIsLoading(false); // Stop loading after API call
          }
      };
      const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // This enables the AM/PM format
        });
    };
    const convertDuration = (minutes) => {
        const hours = Math.floor(minutes / 60); // Get the full hours
        const remainingMinutes = minutes % 60; // Get the remaining minutes
        return `${hours}h ${remainingMinutes}m`;
    };


    const sortedFlights = flightData
  .filter(flight => 
    flight.price_data.some(price => price.fareIdentifier === "PUBLISHED" && 
      (price.fd.ADULT.cc === "PREMIUM_ECONOMY" || price.fd.ADULT.cc === "ECONOMY"))
  )
  .sort((a, b) => {
    const getPrice = flight => {
      const premiumEconomyPrice = flight.price_data.find(price => 
        price.fareIdentifier === "PUBLISHED" && price.fd.ADULT.cc === "PREMIUM_ECONOMY"
      );
      const economyPrice = flight.price_data.find(price => 
        price.fareIdentifier === "PUBLISHED" && price.fd.ADULT.cc === "ECONOMY"
      );
      const selectedPrice = premiumEconomyPrice || economyPrice;
      return selectedPrice ? selectedPrice.fd.ADULT.fC.TF : Infinity; // If no price, return Infinity
    };
    return getPrice(a) - getPrice(b); // Sort by lowest price
  });
  const [openFlightId, setOpenFlightId] = useState(null);

  const toggleDetails = (id) => {
    setOpenFlightId(openFlightId === id ? null : id);
  };
  return (
    <>
        <div className="main">
            <div className="pagewrap modify-search">
              <div className='head-wrapper'>
                  <div className='flight-search'>
                  <div className="container">
                    <ul className="nav nav-tabs border-0" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="oneway-tab" data-bs-toggle="tab" data-bs-target="#oneway" type="button" role="tab" aria-controls="oneway" aria-selected="true">
                                <span className="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"></span>One-way
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="return-tab" data-bs-toggle="tab" data-bs-target="#return" type="button" role="tab" aria-controls="return" aria-selected="false" tabIndex="-1">
                                <span className="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"></span>Return
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="multiCity-tab" data-bs-toggle="tab" data-bs-target="#multiCity" type="button" role="tab" aria-controls="multiCity" aria-selected="false" tabIndex="-1">
                                <span className="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"></span>Multi-city
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div id="oneway" class="tab-pane active" role="tabpanel" aria-labelledby="oneway-tab">
                            <div className="row">
                                <div className="col-12">
                                    <div className="search-pan row mx-0 theme-border-radius">
                                        <div className="col-12 col-lg-4 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                            <div className="form-group">
                                                <i className="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                <input type="text" className="form-control ps-5" id="onewayOrigin" placeholder="Origin" />
                                                <button class="pos-swap"><i className="bi bi-arrow-left-right pl-1"></i></button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                            <div className="form-group">
                                                <i className="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                <input type="text" className="form-control ps-5" id="onewayDestination" placeholder="Destination" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2">
                                            <div className="form-control form-group d-flex">
                                                <i className="bi bi-calendar3 position-absolute h2 icon-pos"></i>
                                                <span className="dep-date-input">
                                                    <input type="text" className="cal-input hasDatepicker" placeholder="Depart Date" id="datepicker" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-3 ps-0 mb-2 mb-lg-0 mb-xl-0 pe-0 pe-lg-2">
                                            <div className="dropdown" id="myDD">
                                                <button className="dropdown-toggle form-control" type="button" id="travellerInfoOneway" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-person-lines-fill position-absolute h2 icon-pos"></i>
                                                    <span className="text-truncate">1 Traveller(s), Economy </span>
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="travellerInfoOneway">
                                                    <ul className="drop-rest">
                                                        <li>
                                                            <div className="d-flex">Select Adults</div>
                                                            <div className="ms-auto input-group plus-minus-input">
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="minus" data-field="onewayAdult">
                                                                        <i className="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input className="input-group-field" type="number" name="onewayAdult" value="0" />
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="plus" data-field="onewayAdult">
                                                                        <i className="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">Select Child</div>
                                                            <div className="ms-auto input-group plus-minus-input">
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="minus" data-field="onewayChild">
                                                                        <i className="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input className="input-group-field" type="number" name="onewayChild" value="0" />
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="plus" data-field="onewayChild">
                                                                        <i className="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">Select Infants</div>
                                                            <div className="ms-auto input-group plus-minus-input">
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="minus" data-field="onewayInfant">
                                                                        <i className="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input className="input-group-field" type="number" name="onewayInfant" value="0" />
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="plus" data-field="onewayInfant">
                                                                        <i className="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <label className="radio-inline">
                                                                <input type="radio" name="class" value="Economy" className="me-2" />Economy </label>
                                                        </li>
                                                        <li>
                                                            <label className="radio-inline">
                                                                <input type="radio" name="class" value="Special" className="me-2" />Premium Economy </label>
                                                        </li>
                                                        <li>
                                                            <label className="radio-inline">
                                                                <input type="radio" name="class" value="Business" className="me-2" />Business </label>
                                                        </li>
                                                        <li>
                                                            <label className="radio-inline">
                                                                <input type="radio" name="class" value="First" className="me-2" />First Class </label>
                                                        </li>
                                                        <li>
                                                            <button type="button" className="btn btn" onclick="">Done</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-2 px-0">
                                            <button type="submit" className="btn btn-search">
                                                <span className="fw-bold">Search</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-check-inline">
                                                <label className="check-wrap">Refundable Flights
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span> </label>
                                            </div>
                                            <div className="form-check-inline">
                                                <label className="check-wrap"> Non Stop Flights
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span> </label>
                                            </div>
                                            <div className="form-check-inline">
                                                <label className="check-wrap"> GDS Special Return
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span> </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="return" className="tab-pane fade" role="tabpanel" aria-labelledby="return-tab">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="search-pan row mx-0 theme-border-radius">
                                        <div className="col-12 col-lg-4 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                            <div className="form-group">
                                                <i className="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                <input type="text" className="form-control ps-5" id="returnOrigin" placeholder="Origin" />
                                                <button className="pos-swap"><i className="bi bi-arrow-left-right pl-1"></i></button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                            <div className="form-group">
                                                <i className="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                <input type="text" className="form-control ps-5" id="returnDestination" placeholder="Destination" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2">
                                            <div className="form-control form-group d-flex">
                                                <i className="bi bi-calendar3 position-absolute h2 icon-pos"></i>
                                                <span className="dep-date-input">
                                                    <input type="text" className="cal-input hasDatepicker" placeholder="Depart Date" id="datepicker1" />
                                                </span>
                                                <span className="arv-date-input ms-2">
                                                    <input type="text" className="cal-input" placeholder="Return Date" id="datepickerNull" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-3 ps-0 mb-2 mb-lg-0 mb-xl-0 pe-0 pe-lg-2">
                                            <div className="dropdown" id="myDDReturn">
                                                <button className="dropdown-toggle form-control" type="button" id="travellerInfoReturn" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-person-lines-fill position-absolute h2 icon-pos"></i>
                                                    <span className="text-truncate">1 Traveller(s), Economy </span>
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="travellerInfoReturn">
                                                    <ul className="drop-rest">
                                                        <li>
                                                            <div className="d-flex">Select Adults</div>
                                                            <div className="ms-auto input-group plus-minus-input">
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="minus" data-field="onewayAdult">
                                                                        <i className="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input className="input-group-field" type="number" name="onewayAdult" value="0" />
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="plus" data-field="onewayAdult">
                                                                        <i className="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">Select Child</div>
                                                            <div className="ms-auto input-group plus-minus-input">
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="minus" data-field="onewayChild">
                                                                        <i className="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input className="input-group-field" type="number" name="onewayChild" value="0" />
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="plus" data-field="onewayChild">
                                                                        <i className="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">Select Infants</div>
                                                            <div className="ms-auto input-group plus-minus-input">
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="minus" data-field="onewayInfant">
                                                                        <i className="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input className="input-group-field" type="number" name="onewayInfant" value="0" />
                                                                <div className="input-group-button">
                                                                    <button type="button" className="circle" data-quantity="plus" data-field="onewayInfant">
                                                                        <i className="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <label className="radio-inline">
                                                                <input type="radio" name="class" value="Economy" className="me-2" />Economy </label>
                                                        </li>
                                                        <li>
                                                            <label className="radio-inline">
                                                                <input type="radio" name="class" value="Special" className="me-2" />Premium Economy </label>
                                                        </li>
                                                        <li>
                                                            <label className="radio-inline">
                                                                <input type="radio" name="class" value="Business" className="me-2" />Business </label>
                                                        </li>
                                                        <li>
                                                            <label className="radio-inline">
                                                                <input type="radio" name="class" value="First" className="me-2" />First Class </label>
                                                        </li>
                                                        <li>
                                                            <button type="button" className="btn btn" onclick="">Done</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-2 px-0">
                                            <button type="submit" className="btn btn-search" >
                                                <span className="fw-bold">Search</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="multiCity" className="tab-pane fade" role="tabpanel" aria-labelledby="multiCity-tab">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="search-pan row mx-0 theme-border-radius">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-lg-6 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                                    <div className="form-group">
                                                        <i className="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                        <input type="text" className="form-control ps-5" id="multiOrigin" placeholder="Origin" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2">
                                                    <div className="form-group">
                                                        <i className="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                        <input type="text" className="form-control ps-5" id="multiDestination" placeholder="Destination" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2 pe-xl-2">
                                                    <div className="form-control form-group d-flex">
                                                        <i className="bi bi-calendar3 position-absolute h2 icon-pos"></i>
                                                        <span className="dep-date-input">
                                                            <input type="text" className="cal-input hasDatepicker" placeholder="Depart Date" id="datepicker3" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6 col-xl-4 ps-0 mb-2 mb-lg-0 mb-xl-0 pe-0 pe-lg-0 pe-xl-0">
                                                    <div className="dropdown" id="myDDRound">
                                                        <button className="dropdown-toggle form-control" type="button" id="travellerInfoMulti" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="bi bi-person-lines-fill position-absolute h2 icon-pos"></i>
                                                            <span className="text-truncate">1 Traveller(s), Economy </span>
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="travellerInfoMulti">
                                                            <ul className="drop-rest">
                                                                <li>
                                                                    <div className="d-flex">Select Adults</div>
                                                                    <div className="ms-auto input-group plus-minus-input">
                                                                        <div className="input-group-button">
                                                                            <button type="button" className="circle" data-quantity="minus" data-field="onewayAdult">
                                                                                <i className="bi bi-dash"></i>
                                                                            </button>
                                                                        </div>
                                                                        <input className="input-group-field" type="number" name="onewayAdult" value="0" />
                                                                        <div className="input-group-button">
                                                                            <button type="button" className="circle" data-quantity="plus" data-field="onewayAdult">
                                                                                <i className="bi bi-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="d-flex">Select Child</div>
                                                                    <div className="ms-auto input-group plus-minus-input">
                                                                        <div className="input-group-button">
                                                                            <button type="button" className="circle" data-quantity="minus" data-field="onewayChild">
                                                                                <i className="bi bi-dash"></i>
                                                                            </button>
                                                                        </div>
                                                                        <input className="input-group-field" type="number" name="onewayChild" value="0" />
                                                                        <div className="input-group-button">
                                                                            <button type="button" className="circle" data-quantity="plus" data-field="onewayChild">
                                                                                <i className="bi bi-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="d-flex">Select Infants</div>
                                                                    <div className="ms-auto input-group plus-minus-input">
                                                                        <div className="input-group-button">
                                                                            <button type="button" className="circle" data-quantity="minus" data-field="onewayInfant">
                                                                                <i className="bi bi-dash"></i>
                                                                            </button>
                                                                        </div>
                                                                        <input className="input-group-field" type="number" name="onewayInfant" value="0" />
                                                                        <div className="input-group-button">
                                                                            <button type="button" className="circle" data-quantity="plus" data-field="onewayInfant">
                                                                                <i className="bi bi-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <label className="radio-inline">
                                                                        <input type="radio" name="class" value="Economy" className="me-2" />Economy </label>
                                                                </li>
                                                                <li>
                                                                    <label className="radio-inline">
                                                                        <input type="radio" name="class" value="Special" className="me-2" />Premium Economy </label>
                                                                </li>
                                                                <li>
                                                                    <label className="radio-inline">
                                                                        <input type="radio" name="class" value="Business" className="me-2" />Business
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <label className="radio-inline">
                                                                        <input type="radio" name="class" value="First" className="me-2" />First Class </label>
                                                                </li>
                                                                <li>
                                                                    <button type="button" className="btn btn" onclick="">Done</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-0 mt-md-0 mt-lg-0 mt-xl-2">
                                                <div className="col-12 col-lg-4 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                                    <div className="form-group">
                                                        <i className="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                        <input type="text" className="form-control ps-5" id="multiOrigin2" placeholder="Origin" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                                    <div className="form-group">
                                                        <i className="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                        <input type="text" className="form-control ps-5" id="multiDestination2" placeholder="Destination" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2">
                                                    <div className="form-control form-group d-flex">
                                                        <i className="bi bi-calendar3 position-absolute h2 icon-pos"></i>
                                                        <span className="dep-date-input">
                                                            <input type="text" className="cal-input hasDatepicker" placeholder="Depart Date" id="datepicker4" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-4 px-0">
                                                    <div className="row">
                                                        <div className="col-12 col-lg-6 col-xl-7 mb-2 mb-md-2 mb-lg-0 d-flex justify-content-center align-items-center">
                                                            <button type="submit" className="btn btn-light" id="add-button">
                                                                <span className="fw-bold">+ Add City</span> </button>
                                                            <button type="submit" className="btn" id="remove-button"> <span className="fw-bold">Close</span> </button>
                                                        </div>
                                                        <div className="col-12 col-lg-6 col-xl-5">
                                                            <button type="submit" className="btn btn-search" onclick="">
                                                                <span className="fw-bold">Search</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="filter-sec py-2">
            <div className="container">
                <div className="d-flex justify-content-between">
                    <div className="fw-bold"><i className="bi bi-funnel pe-2"></i>Filters</div>
                    <div>
                        <a className="btn flt-btn" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">Reset All
                        </a>
                    </div>
                </div>
            </div>
        </div>







        <div className="content-section">
           
            <div className="srp py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12 my-2">
                            <div className="d-flex justify-content-between align-items-start">
                                <div>
                                    <div className="fw-bold">Berlin (BER)<i className="bi bi-arrow-right mx-2"></i>London (LHR)
                                    </div>
                                    <div className="mb-1 font-small">Sun, Mar 30</div>
                                </div>
                                <div>
                                    <span className="font-small">Showing 118 of 118 flights.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="row">
                                <div className="col-12 col-md-12 d-none d-md-block">
                                    <div className="row g-0 border theme-border-radius p-2 theme-bg-accent-three">
                                        <div className="col-md-3">
                                            <span className="font-small fw-bold">Airline</span>
                                        </div>
                                        <div className="col-md-2">
                                            <span className="font-small fw-bold">Depart</span>
                                        </div>
                                        <div className="col-md-2">
                                            <span className="font-small fw-bold">Duration</span>
                                        </div>
                                        <div className="col-md-2">
                                            <span className="font-small fw-bold">Arrive</span>
                                        </div>
                                        <div className="col-md-3 text-md-center">
                                            <span className="font-small fw-bold">Price
                                                
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 d-md-block d-md-none">
                                    <button className="btn w-100 border theme-border-radius p-2 theme-bg-accent-three" type="button">
                                        <i className="bi bi-sliders me-2"></i><span className="visible-xs font-medium">Sort
                                            Depart</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    {isLoading ? (
        // Render Shimmer UI when loading
        <>
          <ShimmerPlaceholder />
          <ShimmerPlaceholder />
          <ShimmerPlaceholder />
        </>
      ) : (
        // Render flight data when loading is complete
        sortedFlights.map((flight, index) => (
          <div key={index} className="col-12 mb-3 aos-init aos-animate" data-aos="fade-up">
            <div className="row g-0 border theme-border-radius theme-box-shadow p-2 align-items-center theme-bg-white">
              <div className="col-12 col-md-3">
                <div className="d-flex">
                  <div>
                  <img
                    src={`/logo/${flight.airline_code}.png`}
                    className="img-fluid theme-border-radius"
                    alt={flight.airline_name}
                    title={`Airline ${flight.airline_name}`}
                    />
                  </div>
                  <div className="d-flex flex-column ms-2">
                    <span className="font-small d-inline-flex mb-0 align-middle">{flight.airline_name}</span>
                    <span className="font-small d-inline-flex mb-0 align-middle">{flight.airline_code}</span>
                  </div>
                </div>
                <div>
                  <a style={{ cursor: "pointer" }} onClick={() => toggleDetails(flight.id)} className="font-small">Flight Details</a>
                </div>
                
              </div>
              <div className="col-4 col-md-2">
                <div className="fw-bold">{formatTime(flight.departure_time)}</div>
                <div className="font-small">{flight.departure_code}</div>
              </div>
              <div className="col-4 col-md-2">
                <div className="font-small">{convertDuration(flight.duration)}</div>
                <span className="stops"></span>
                <div className="font-small">{flight.stops === 0 ? 'Non Stop' : `${flight.stops} Stop(s)`}</div>
              </div>
              <div className="col-4 col-md-2">
                <div className="fw-bold">{formatTime(flight.arival_time)}</div>
                <div className="font-small">{flight.arival_code}</div>
              </div>
              <div className="col-12 col-md-3 text-center mt-md-0 mt-2">
                {(() => {
                  const premiumEconomyPrice = flight.price_data.find(price =>
                    price.fareIdentifier === "PUBLISHED" && price.fd.ADULT.cc === "PREMIUM_ECONOMY"
                  );

                  const economyPrice = flight.price_data.find(price =>
                    price.fareIdentifier === "PUBLISHED" && price.fd.ADULT.cc === "ECONOMY"
                  );

                  const selectedPrice = premiumEconomyPrice || economyPrice;

                  return selectedPrice ? (
                    <div className="fw-bold">
                      <i className="bi bi-currency-rupee ms-2"></i>
                      {selectedPrice.fd.ADULT.fC.TF.toFixed(2)}
                      <button
                        type="submit"
                        className="btn-select btn btn-effect"
                        onClick={() => window.location.href = `bookibg-details?bookingid=${selectedPrice.id}`}
                      >
                        <span className="font-small">Select</span>
                      </button>
                    </div>
                  ) : (
                    <div className="font-small">Price Unavailable</div>
                  );
                })()}
              </div>
              {openFlightId === flight.id && (
                <div class="px-3">
                <ul class="row py-3">
                    
                    <li class="col-12 col-md-6 col-lg-3 pb-3">
                        <div class="font-medium fw-bold text-uppercase">New Delhi
                        </div>
                        <div class="font-medium fw-bold">07:20 | <span class="fw-normal">Sat,
                                Mar 30</span></div>
                        <div class="font-small">Indira Gandhi, T - 1D</div>
                    </li>
                    <li class="col-12 col-md-6 col-lg-3 pb-3">
                        <div class="float-start"><i class="bi bi-clock pe-2 fs-6"></i></div>
                        <div class="float-start"> <span class="font-medium d-block">03h
                                15m</span>
                            <span class="font-small d-block">{flight.stops === 0 ? 'Non Stop' : `${flight.stops} Stop(s)`}</span>
                        </div>
                    </li>
                    <li class="col-12 col-md-6 col-lg-3 pb-3">
                        <div class="font-medium fw-bold text-uppercase">KOCHI</div>
                        <div class="font-medium fw-bold">10:35 | <span class="fw-normal">Sat,
                                Mar 30</span></div>
                        <div class="font-small">Cochin, T - 2</div>
                    </li>
                    <li class="col-12 col-md-6 col-lg-3 pb-3"> <span class="font-small text-uppercase fw-bold"> <i class="bi bi-briefcase me-2 fs-6"></i> Baggage </span> <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span class="font-small d-block">CABIN : 7 Kgs</span> </li>
                </ul>
            </div>
          )}
    
            </div>
          </div>
        ))
      )}
                        
                        
                        
                    
                        
                        
                        
                        
                        
                        
                    
                        
                    </div>
                </div>
            </div>


        </div>

                  </div>
              </div>  
            </div>
        </div>
    </>
  )
}
