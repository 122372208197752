import React from 'react'
import { useLocation } from 'react-router-dom';
export default function Header() {
    const location = useLocation();
    const currentURI = location.pathname;
  return (
    <>
    <header id="header" className="fixed-top d-flex align-items-center">
    <div className="container d-flex align-items-center">
      {/* <h1 className="logo me-auto"><a href="/"><span className='first_logo'>CHALÔ</span><span className='second_logo'>PHIR</span></a></h1> */}
      <a href="/" className="logo me-auto">
        <img src="images/logo.png" alt=""/>
        {/* <h1 className="logo me-auto"><a href="/"><span className='first_logo'>CHALÔ</span><span className='second_logo'>PHIR<span class="badge">&reg;</span></span></a></h1>
         */}
      </a>

      <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>
          <li><a className={currentURI === '/' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="/">Home</a></li>
          <li><a className={currentURI === '/flight' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="/flight">Flight</a></li>
          <li><a className={currentURI === '/hotel' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="/hotel">Hotel</a></li>
          <li><a className={currentURI === '/evisa' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="/evisa">E-Visa</a></li>
          <li><a className={currentURI === '/checklist' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="/checklist">Visa Checklist</a></li>
          <li><a className={currentURI === '/packages' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="/packages">Packages</a></li>
          <li><a className={currentURI === '/about-us' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="/about-us">About</a></li>
          <li><a className={currentURI === '/login' ? 'nav-link scrollto active' : 'nav-link scrollto'} href="/login">Login</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
      {/* <a href="tel:+919555586666" className="get-started-btn scrollto">Call Us 24/7</a> */}
      <a href='' className="get-started-btn1 scrollto"><img src='https://www.iata.org/contentassets/3e83770142a040d688e269bb2f709b7b/iata-logo-header.svg' /></a>
    </div>
    <a aria-label="phone" href="tel:+919555586666" className="float1" target="_blank">
<i class="fa fa-phone my-float"></i></a>
  </header>
    </>
  )
}
