import React,{ useState, useEffect } from 'react'
export default function Evisa() {
  const [isActiveSingle, setActiveSingle] = useState(true);
  const [isActiveMultiple, setActiveMultiple] = useState(false);

  const toggleSingleClass = () => {
    setActiveSingle(true);
    setActiveMultiple(false);
  };

  const toggleMultipleClass = () => {
    setActiveMultiple(true);
    setActiveSingle(false);
  };
  return (
    <>
   <main id="main">
      <div className='HeroSection_heroContainer__fecG2'>
          <div className='container newevisaclass'>
            <div className='row'>
                <div className='col-sm-12 col-md-6 col-lg-6'>
                  <h3 className='evisah3'>
                    <span className='HeroSection_welcomeText__tCxvZ'>Welcome to</span>
                    <br/>
                    <span className='HeroSection_fontChange__CEeC6'>eDubai Visa Portal</span>
                  </h3>
                  <h6 className='evisah6'>
                  Apply Now For Your UAE Visit Visa and Dubai Tourist Visa Online in
                  <span> 3 Simple Steps.</span>
                  </h6>
                  <div className='HeroSection_stepsContainer__M0r33'>
                    <div className='HeroSection_step__7tWVV HeroSection_step1__Cifs4'>
                      <p className='css-1ywsn9k'>Step 1</p>
                      <p className='css-1hqei1c'>Fill the Application</p>
                    </div>
                    <div className='HeroSection_step__7tWVV HeroSection_step2__P_4cY'>
                      <p className='css-1ywsn9k'>Step 2</p>
                      <p className='css-1hqei1c'>Pay the Fee</p>
                    </div>
                    <div className='HeroSection_step__7tWVV HeroSection_step3__c0686'>
                      <p className='css-1ywsn9k'>Step 3</p>
                      <p className='css-1hqei1c'>Get Your VISA</p>
                    </div>
                  </div>
                  <br/>
                  <a className="HeroSection_appBtn__4S0uJ" href="/apply">Apply Now</a>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-6'>
                    <img src="https://www.edubaivisa.ae/images/hero-img.png" />
                </div>
            </div>
          </div>
      </div>
      <div className='VisaSection_visaContainer__lpJPw'>
          <h3 className='css-mkg29l'>Choose Your Visa</h3>
          <p className="css-1u4yx72"><img src="https://flagcdn.com/w40/ae.png" alt="country flags" />All visas are valid across the United Arab Emirates and can be used for all modes of transport.</p>
          <div className='VisaSection_mainVisaContainer__KUODU'>
            <div className="VisaSection_tabList__Zc6wR">
                <ul className="VisaSection_taebSwitch__SuGOs">
                <li
        className={isActiveSingle ? 'VisaSection_tabs__fjRzK VisaSection_activeTabs__yyuMd' : 'VisaSection_tabs__fjRzK'}
        onClick={toggleSingleClass}
      >
        Single Entry
      </li>
      <li
        className={isActiveMultiple ? 'VisaSection_tabs__fjRzK VisaSection_activeTabs__yyuMd' : 'VisaSection_tabs__fjRzK'}
        onClick={toggleMultipleClass}
      >
        Multiple Entry
      </li>
                </ul>
              </div>
            </div>
            <br/>
            <div className='container'>
              <div className='row'>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                  <div className="card">
                  <label className="VisaCardV2_switch__heP_y"><input type="checkbox" /><small></small></label>
                  <div className='VisaCardV2_visaContentContainer__ng1uG'>
                    <div className='VisaCardV2_activeContent__uF18_'>
                      <div className='css-122ud6i'>
                        <h5 className='css-qdvnpr'>30 Days</h5>
                        <p className='css-rn90z'>Multiple Entry Visa</p>
                        {/* <h3 className='css-1brr7ct'>$400</h3> */}
                        <div className='VisaCardV2_line__mDleZ'></div>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Service Type <span>Regular Visa-Single Entry</span></p>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Processing Time <span>1-3 business days</span></p>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Validity <span>60 days from date of issue</span></p>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Stay Period <span>14 days from date of entry</span></p>
                        <button className="css-kurovt" tabindex="0" type="button">Apply Now<span className="css-w0pj6f"></span></button>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                <div className="card">
                  <label className="VisaCardV2_switch__heP_y"><input type="checkbox" /><small></small></label>
                  <div className='VisaCardV2_visaContentContainer__ng1uG'>
                    <div className='VisaCardV2_activeContent__uF18_'>
                      <div className='css-122ud6i'>
                        <h5 className='css-qdvnpr'>30 Days</h5>
                        <p className='css-rn90z'>Multiple Entry Visa</p>
                        {/* <h3 className='css-1brr7ct'>$400</h3> */}
                        <div className='VisaCardV2_line__mDleZ'></div>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Service Type <span>Regular Visa-Single Entry</span></p>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Processing Time <span>1-3 business days</span></p>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Validity <span>60 days from date of issue</span></p>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Stay Period <span>14 days from date of entry</span></p>
                        <button className="css-kurovt" tabindex="0" type="button">Apply Now<span className="css-w0pj6f"></span></button>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                <div className="card">
                  <label className="VisaCardV2_switch__heP_y"><input type="checkbox" /><small></small></label>
                  <div className='VisaCardV2_visaContentContainer__ng1uG'>
                    <div className='VisaCardV2_activeContent__uF18_'>
                      <div className='css-122ud6i'>
                        <h5 className='css-qdvnpr'>30 Days</h5>
                        <p className='css-rn90z'>Multiple Entry Visa</p>
                        {/* <h3 className='css-1brr7ct'>$400</h3> */}
                        <div className='VisaCardV2_line__mDleZ'></div>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Service Type <span>Regular Visa-Single Entry</span></p>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Processing Time <span>1-3 business days</span></p>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Validity <span>60 days from date of issue</span></p>
                        <p className="css-1228dmr"><svg className="css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"></path></svg>Stay Period <span>14 days from date of entry</span></p>
                        <button className="css-kurovt" tabindex="0" type="button">Apply Now<span className="css-w0pj6f"></span></button>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
        </div>
        
   </main>
    </>
  )
}
