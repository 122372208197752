import React from 'react';
import './App.css';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Layout from './Components/Layout';
import Home from './Pages/Home/Home';
import Flight from './Pages/Flight/Flight';
import Hotel from './Pages/Hotel/Hotel';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Package from './Pages/Package/Package';
import Vissa from './Pages/VissaList/Vissa';
import Login from './Pages/User/Login';
import NotFound from './Pages/NotFound/NotFound';
import BlogDetails from './Pages/Blogs/BlogDetails';
import Blog from './Pages/Blogs/Blog';
import HotelDetail from './Pages/Hotel/HotelDetail';
import Evisa from './Pages/Evisa/evisa';
import Apply from './Pages/Evisa/apply';
import BookingDetails from './Pages/Flight/BookingDetails';
function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/flight" element={<Flight />} />
          <Route path="/bookibg-details" element={<BookingDetails />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/packages" element={<Package />} />
          <Route path="/checklist" element={<Vissa />} />
          <Route path="/login" element={<Login />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="blog-details/:str" element={<BlogDetails />} />
          <Route path="hotel-details/:str" element={<HotelDetail />} />
          <Route path="/evisa" element={<Evisa />} />
          <Route path="/apply" element={<Apply />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
